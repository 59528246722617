define("ember-affine-gesture/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const userAgent = window.navigator.userAgent;
  const iPadSafari = !!userAgent.match(/iPad/i) && // Detect iPad first.
  !!userAgent.match(/WebKit/i) && // Filter browsers with webkit engine only
  !userAgent.match(/CriOS/i) && // Eliminate Chrome & Brave
  !userAgent.match(/OPiOS/i) && // Rule out Opera
  !userAgent.match(/FxiOS/i) && // Rule out Firefox
  !userAgent.match(/FocusiOS/i); // Eliminate Firefox Focus as well!

  const element = document.getElementById('fullScreenButton');

  function iOS() {
    if (userAgent.match(/ipad|iphone|ipod/i)) {
      const iOS = {};
      iOS.majorReleaseNumber = +userAgent.match(/OS (\d)?\d_\d(_\d)?/i)[0].split('_')[0].replace('OS ', '');
      return iOS;
    }
  }

  const Envelope = Ember.Object.extend({
    myID: null,
    inmotion: false,
    origin: 0,
    rotate: 0,
    scale: 1,
    translateX: 0,
    translateY: 0,
    selfReference: null,

    init() {
      this._super(...arguments);

      this.set('tl', {
        x: 0,
        y: 0,
        a: 0,
        r: 0
      });
      this.set('tr', {
        x: 0,
        y: 0,
        a: 0,
        r: 0
      });
      this.set('br', {
        x: 0,
        y: 0,
        a: 0,
        r: 0
      });
      this.set('bl', {
        x: 0,
        y: 0,
        a: 0,
        r: 0
      });
    }

  });

  var _default = Ember.Controller.extend({
    envelopes: null,

    init() {
      this._super(...arguments); // Don't forget this!
      // Write your code here.
      // this.set('selfReference', this);
      // this.set('selfReference', Ember.inject.controller('application'));
      // this.set('envelopes', A([]));  //  create enumberable array in init


      console.log("!! ", JSON.stringify(this.get('envelopes'))); // this.set('envelope', EmberObject.extend({
      //   myID: null,
      //   inmotion: false,
      //   tl:{x:0, y:0, a:0, r:0},
      // 	tr:{x:0, y:0, a:0, r:0},
      // 	br:{x:0, y:0, a:0, r:0},
      // 	bl:{x:0, y:0, a:0, r:0}
      // }));
      // console.log("fooey");
      // console.log("fooey", JSON.Stringify(this.get('envelopes')));
    },

    createEnvelope(data) {
      console.log('createEnvelope: ', JSON.stringify(data));
      return Envelope.create({
        myID: data.myID,
        inmotion: data.inmotion,
        tl: data.tl,
        tr: data.tr,
        br: data.br,
        bl: data.bl,
        origin: data.origin,
        rotate: data.rotate,
        scale: data.scale,
        translateX: data.translateX,
        translateY: data.translateY
      }); // return this.get('myID');
    },

    envelopeArray() {
      const env = this.get('envelopes');
      console.log('envelopeArray', JSON.stringify(env));
      return this.get('envelopes');
    },

    activeTransforms(data) {
      //	console.log("???", JSON.stringify(data));
      const self = this; // let env = this.get('envelopes');

      const _envelopes = this.get('envelopes');

      if (this.envelopes) {
        let _envelope = this.envelopes.findBy('myID', data.myID); //	console.log('_envelope A', JSON.stringify(_envelope) );
        // var temp = self.get('envelopes').objectAt(0);
        // Ember.set(temp, "a", 2);
        //		console.log('');


        if (_envelope) {
          _envelope.inmotion = data.inmotion;
          _envelope.tl = data.tl;
          _envelope.tr = data.tr;
          _envelope.br = data.br;
          _envelope.bl = data.bl;
          _envelope.origin = data.origin;
          _envelope.rotate = data.rotate;
          _envelope.scale = data.scale;
          _envelope.translateX = data.translateX;
          _envelope.translateY = data.translateY; //console.log('_envelope B', JSON.stringify(_envelope) );
          //			console.log('envelopes!', JSON.stringify(_envelope) );
          //		console.log("+++", JSON.stringify(_envelope));
        } else {
          //const newEnvelope = createEnvelope(data);
          this.get('envelopes').pushObject(createEnvelope(data)); // this.envelope.create({
          // 	myID: null,
          //   inmotion: false,
          //   tl:{x:0, y:0, a:0, r:0},
          //   tr:{x:0, y:0, a:0, r:0},
          //   br:{x:0, y:0, a:0, r:0},
          //   bl:{x:0, y:0, a:0, r:0}
          // });
        }

        ;
      } else {
        // const env = self.createEnvelope(data);
        this.set('envelopes', Ember.A([Envelope.create({
          myID: data.myID,
          inmotion: data.inmotion,
          tl: data.tl,
          tr: data.tr,
          br: data.br,
          bl: data.bl,
          origin: data.origin,
          rotate: data.rotate,
          scale: data.scale,
          translateX: data.translateX,
          translateY: data.translateY
        })])); // this.get('envelopes').pushObject(createEnvelope(data));
        //	console.log('nnn', JSON.stringify(this.get('envelopes')));
        // this.get('envelopes').pushObject(createEnvelope(data));
        // this.envelope.create({
        // 	myID: null,
        //   inmotion: false,
        //   tl:{x:0, y:0, a:0, r:0},
        //   tr:{x:0, y:0, a:0, r:0},
        //   br:{x:0, y:0, a:0, r:0},
        //   bl:{x:0, y:0, a:0, r:0}
        // });
      }
      /*
      
      
      	*/
      // env.reopen(
      //
      //
      // );
      //	this.set('envelopes', data);
      // const env = this get('envelopes');
      // EmberInspector.inspect(data);
      // console.log('i: '+JSON.stringify(data));

    },

    actions: {
      toggleFullScreen() {
        console.log("tiaglo");

        if (document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement) {
          if (document.cancelFullScreen) {
            document.cancelFullScreen();
          } else {
            if (document.mozCancelFullScreen) {
              document.mozCancelFullScreen();
            } else {
              if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
              }
            }
          }
        } else {
          const _element = document.documentElement;

          if (_element.requestFullscreen) {
            _element.requestFullscreen();
          } else {
            if (_element.mozRequestFullScreen) {
              _element.mozRequestFullScreen();
            } else {
              if (_element.webkitRequestFullscreen) {
                _element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
              }
            }
          }
        }
      }

    } // }
    //
    // const _toggleFullScreen = function _toggleFullScreen() {
    // 	if (document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement) {
    // 		if (document.cancelFullScreen) {
    // 			document.cancelFullScreen();
    // 		} else {
    // 			if (document.mozCancelFullScreen) {
    // 				document.mozCancelFullScreen();
    // 			} else {
    // 				if (document.webkitCancelFullScreen) {
    // 					document.webkitCancelFullScreen();
    // 				}
    // 			}
    // 		}
    // 	} else {
    // 		const _element = document.documentElement;
    // 		if (_element.requestFullscreen) {
    // 			_element.requestFullscreen();
    // 		} else {
    // 			if (_element.mozRequestFullScreen) {
    // 				_element.mozRequestFullScreen();
    // 			} else {
    // 				if (_element.webkitRequestFullscreen) {
    // 					_element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    // 				}
    // 			}
    // 		}
    // 	}
    // };
    //
    // const userAgent = window.navigator.userAgent;
    //
    // 	const iPadSafari =
    // 		!!userAgent.match(/iPad/i) &&  		// Detect iPad first.
    // 		!!userAgent.match(/WebKit/i) && 	// Filter browsers with webkit engine only
    // 		!userAgent.match(/CriOS/i) &&		// Eliminate Chrome & Brave
    // 		!userAgent.match(/OPiOS/i) &&		// Rule out Opera
    // 		!userAgent.match(/FxiOS/i) &&		// Rule out Firefox
    // 		!userAgent.match(/FocusiOS/i);		// Eliminate Firefox Focus as well!
    //
    // 	const element = document.getElementById('fullScreenButton');
    //
    // 	function iOS() {
    // 		if (userAgent.match(/ipad|iphone|ipod/i)) {
    // 			const iOS = {};
    // 			iOS.majorReleaseNumber = +userAgent.match(/OS (\d)?\d_\d(_\d)?/i)[0].split('_')[0].replace('OS ', '');
    // 			return iOS;
    // 		}
    // 	}
    //
    // 	if (element !== null) {
    // 		if (userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
    // 			element.className += ' hidden';
    // 		} else if (userAgent.match(/iPad/i) && iOS().majorReleaseNumber < 12) {
    // 			element.className += ' hidden';
    // 		} else if (userAgent.match(/iPad/i) && !iPadSafari) {
    // 			element.className += ' hidden';
    // 		} else {
    // 			element.addEventListener('click', _toggleFullScreen, false);
    // 		}
    // 	}
    //
    //

  });

  _exports.default = _default;
});