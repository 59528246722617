define("ember-affine-gesture/components/webcam-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // playsinline autoplay
    tagName: 'video',
    classNames: ['video'],
    attributeBindings: ['id', 'playsinline', 'autoplay'],
    // id: 'livevideo',
    playsinline: true,
    autoplay: true,

    didInsertElement() {
      console.log("elementInserted"); // Put variables in global scope to make them available to the browser console.

      const video = document.querySelectorAll('video');
      const constraints = {
        audio: false,
        video: true
      };

      function handleSuccess(stream) {
        console.log("jellyvena");
        window.stream = stream; // make stream available to browser console

        for (var i = 0; i < video.length; i++) {
          video[i].srcObject = stream;
        }
      }

      function handleError(error) {
        console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
      }

      navigator.mediaDevices.getUserMedia(constraints).then(handleSuccess).catch(handleError);
    }

  });

  _exports.default = _default;
});