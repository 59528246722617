define("ember-affine-gesture/components/affine-gestureY", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // const {$} = Ember;
  // var clientX, clientY;
  // var localX, localY;
  let showEvents = false;

  var _default = Ember.Component.extend({
    attributeBindings: ['style'],
    classNames: ['affineGesture'],
    rotation: 0,
    scale: 1,
    translateX: 0,
    translateY: 0,
    mouseLastX: 0,
    mouseLastY: 0,
    location: {
      x: 0,
      y: 0
    },
    vAngle: 0,
    vDist: 0,
    //transform: rotate(30deg) scale(1.2) skew(10deg) translate(5px, 5px);
    style: Ember.computed('rotation', 'scale', 'translateX', 'translateY', function () {
      return Ember.String.htmlSafe(`transform:
    translate(${this.get('translateX')}px, ${this.get('translateY')}px) rotate(${this.get('rotation')}deg)
            scale(${this.get('scale')})`); // return Ember.String.htmlSafe(`transform: rotate(${this.get('rotation')}deg) scale(${this.get('scale')});
      //       left: ${this.get('translateX')}px;
      //       top: ${this.get('translateY')}px;`);
    }),
    didInsertElement: function () {
      // Initialize some state for this component
      this._super();

      const self = this;
      let loc = {
        x: 0,
        y: 0
      };
      Ember.run.next(this, function () {
        loc.x = this.element.offsetWidth * .5;
        loc.y = this.element.offsetHeight * .5;
        this.set('location', loc); // document.body.addEventListener('touchmove', function(event) {
        //   event.preventDefault();
        //   event.stopImmediatePropagation();
        // }, false);
      });
    },
    actions: {
      start_1_gesture(ev) {
        console.log('start_1_gesture');
        const loc = this.get('location'); // const rAngle = Math.atan2(ev.y, ev.x) - Math.atan2(loc.y, loc.x);

        const origX = loc.x + this.get('translateX');
        const origY = loc.y + this.get('translateY'); //determines vector angle of event location to element origin

        const vectX = ev.x - origX;
        const vectY = ev.y - origY;
        const rAngle = Math.atan2(vectY, vectX);
        this.set('vAngle', rAngle * (180 / Math.PI));
        this.set('vDist', Math.sqrt(vectX * vectX + vectY * vectY));
        this.set('mouseLastX', ev.x);
        this.set('mouseLastY', ev.y); // this.send('showEvent', 'a: '+angle.toFixed(2));
      },

      move_1_gesture(ev) {
        const loc = this.get('location');
        const origX = loc.x + this.get('translateX');
        const origY = loc.y + this.get('translateY'); //determines vector angle of event location to element origin

        const vectX = ev.x - origX;
        const vectY = ev.y - origY;
        const rAngle = Math.atan2(vectY, vectX);
        const angle = rAngle * (180 / Math.PI); //convert radians to degrees

        const deltaA = angle - this.get('vAngle');
        const dist = Math.sqrt(vectX * vectX + vectY * vectY);
        const deltaD = dist - this.get('vDist');
        this.set('rotation', this.get('rotation') + deltaA);
        this.set('vAngle', angle); // const deltaX = ev.x - this.get('mouseLastX');
        // const deltaY = ev.y - this.get('mouseLastY');

        const deltaX = Math.cos(rAngle) * deltaD;
        const deltaY = Math.sin(rAngle) * deltaD;
        this.set('translateX', this.get('translateX') + deltaX);
        this.set('translateY', this.get('translateY') + deltaY);
        this.set('mouseLastX', ev.x);
        this.set('mouseLastY', ev.y); //    this.send('showEvent', 'a: '+angle.toFixed(2));
        // console.log('a: ',angle);
      },

      end_1_gesture(ev) {},

      findPos(obj) {
        // this function will return the offset global position of element obj
        var curleft = curtop = 0; //If the browser supports offsetParent we proceed.

        if (obj.offsetParent) {
          //Every time we find a new object, we add its offsetLeft and offsetTop to curleft and curtop.
          do {
            curleft += obj.offsetLeft;
            curtop += obj.offsetTop; //The tricky bit: return value of the = operator
          } while (obj = obj.offsetParent); //No, this is not a syntax error. I don't want to use == to compare obj to obj.offsetParent (that doesn't make sense anyhow, since an element is never equal to its offsetParent).
          //Instead, I use the = assignment operator to change the value of obj to obj.offsetParent. I explain this trick in more detail in this blog post.

        }

        return [curleft, curtop];
      },

      showEvent(ev) {
        if (showEvents) Ember.$('#events').append(ev + "  ");
      },

      handle_one_touch(ev) {
        Ember.$('#events').append(ev + " 1_touch ");
        clientX = ev.touches[0].clientX;
        clientY = ev.touches[0].clientY;
      },

      handle_two_touches(ev) {
        Ember.$('#events').append(ev + " 2_touches ");
      },

      handle_three_touches(ev) {
        Ember.$('#events').append(ev + " 3_touches ");
      }

    },

    touchStart(ev) {
      // this.send('showEvent', 'touchStart ');
      // this.send('showEvent', ('ev.touches.length: '+ ev.touches.length));
      // this.send('showEvent', ('ev.touches: '+ JSON.stringify(ev.touches)));
      switch (ev.touches.length) {
        case 1:
          console.log('-+-');
          console.log(' ::: ', ev.touches[0].clientX, " ", ev.touches[0].clientY);
          this.send('start_1_gesture', {
            x: ev.touches[0].clientX,
            y: ev.touches[0].clientY
          }); // this.send('start_1_gesture', {x: ev.clientX, y: ev.clientY});

          break;

        case 2:
          this.send('2_touches', ev);
          break;

        case 3:
          this.send('3_touches', ev);
          break;

        default:
          gesture_not_supported(ev);
          break;
      }

      return true;
    },

    touchMove(ev) {
      ev.preventDefault(); // this.send('showEvent', 'touchMove ');
      // if (ev.targetTouches.length >= 1)
      // this.send('showEvent', ('ev.targetTouches: '+ JSON.stringify(ev.targetTouches.item(0))));
      // else
      // this.send('showEvent', ('ev.touches: '+ JSON.stringify(ev.touches.item(0).identifier )));

      switch (ev.touches.length) {
        case 1:
          this.send('move_1_gesture', {
            x: ev.touches[0].clientX,
            y: ev.touches[0].clientY
          });
          break;

        case 2:
          this.send('2_touches', ev);
          break;

        case 3:
          this.send('3_touches', ev);
          break;

        default:
          gesture_not_supported(ev);
          break;
      }

      return false;
    },

    touchEnd(ev) {
      this.send('showEvent', 'touchEnd ');

      switch (ev.touches.length) {
        case 1:
          this.send('end_1_gesture', {
            x: ev.touches[0].clientX,
            y: ev.touches[0].clientY
          });
          break;

        case 2:
          this.send('2_touches', ev);
          break;

        case 3:
          this.send('3_touches', ev);
          break;

        default:
          gesture_not_supported(ev);
          break;
      }

      return true;
    },

    touchCancel(ev) {
      this.send('showEvent', 'touchCancel ');
      return true;
    },

    keyDown() {
      this.send('showEvent', 'keyDown ');
      return true;
    },

    keyUp() {
      this.send('showEvent', 'keyUp ');
      return true;
    },

    keyPress() {
      showEvent('keyPress ');
      return true;
    },

    mouseEnter() {
      this.send('showEvent', 'mouseEnter ');
      return true;
    },

    mouseLeave() {
      this.send('showEvent', 'mouseLeave ');
      return true;
    },

    mouseDown(ev) {
      console.log('ev');
      this.send('showEvent', 'mouseDown '); // this.send('start_1_gesture', ev);
      // console.log('ev', ev.clientX, "  ", ev.clientY);

      this.send('start_1_gesture', {
        x: ev.clientX,
        y: ev.clientY
      });
      return true;
    },

    mouseUp(ev) {
      // this.send('showEvent', 'mouseUp ');
      this.send('end_1_gesture', ev);
      return true;
    },

    mouseMove(ev) {
      //  this.send('showEvent', 'mouseMove ');
      //  this.send('move_1_gesture', ev);
      if (ev.buttons) {
        //this.send('move_1_gesture', ev);
        this.send('move_1_gesture', {
          x: ev.clientX,
          y: ev.clientY
        });
        return true;
      } else {
        return false;
      }
    },

    click() {
      // this.send('showEvent', 'click ');
      return true;
    },

    doubleClick() {
      this.send('showEvent', 'doubleClick ');
      return true;
    },

    contextMenu() {
      this.send('showEvent', 'contextMenu ');
      return true;
    },

    focusIn() {
      this.send('showEvent', 'focusIn ');
      return true;
    },

    focusOut() {
      this.send('showEvent', 'focusOut ');
      return true;
    },

    submit() {
      this.send('showEvent', 'submit ');
      return true;
    },

    change() {
      this.send('showEvent', 'change event!');
      return true;
    },

    input() {
      this.send('showEvent', 'input ');
      return true;
    },

    dragStart(ev) {
      //  this.draggable = false;
      ev.stopImmediatePropagation();
      this.send('showEvent', 'dragStart ');
      return false;
    },

    drag(ev) {
      ev.stopImmediatePropagation(); //  this.send('showEvent', 'drag ');

      this.send('move_1_gesture', {
        x: ev.clientX,
        y: ev.clientY
      });
      return true;
    },

    dragEnter() {
      this.send('showEvent', 'dragEnter ');
      return true;
    },

    dragLeave() {
      this.send('showEvent', 'dragLeave ');
      return true;
    },

    dragOver() {
      //  this.send('showEvent', 'dragOver ');
      return true;
    },

    dragEnd() {
      this.send('showEvent', 'dragEnd ');
      return true;
    },

    drop() {
      this.send('showEvent', 'drop ');
      return true;
    }

  });

  _exports.default = _default;
});