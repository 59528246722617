define("ember-affine-gesture/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gc9KJ42d",
    "block": "{\"symbols\":[],\"statements\":[[2,\" <p id=\\\"events\\\" style=\\\"position:absolute;\\\"></p> \"],[0,\"\\n\"],[1,[27,\"touch-canvas\",null,[[\"envelopes\",\"parController\"],[[23,[\"envelopeArray\"]],[23,[\"selfReference\"]]]]],false],[0,\"\\n\"],[4,\"affine-gesture\",null,[[\"activeTransforms\"],[[23,[\"activeTransforms\"]]]],{\"statements\":[[0,\"  \"],[1,[21,\"webcam-video\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"affine-gesture\",null,[[\"activeTransforms\"],[[23,[\"activeTransforms\"]]]],{\"statements\":[[0,\"  \"],[1,[21,\"webcam-video\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-affine-gesture/templates/application.hbs"
    }
  });

  _exports.default = _default;
});