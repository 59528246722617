define("ember-affine-gesture/services/transforms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    transitems: null,

    init() {
      this._super(...arguments);

      this.set('transitems', Ember.A([]));
      console.log("heyA");
    },

    add(transitem) {
      // console.log('added');
      this.transitems.pushObject(transitem);
    },

    all() {
      return this.transitems;
    },

    update(transitem) {
      const item = this.transitems.findBy('myID', transitem.myID);

      if (item) {
        // console.log('gotit', item);
        //myID:this.get('elementId'),
        item.inmotion = transitem.inmotion;
        item.tl = transitem.tl;
        item.tr = transitem.tr;
        item.br = transitem.br;
        item.bl = transitem.bl;
        item.origin = transitem.origin;
        item.rotate = transitem.rotate;
        item.scale = transitem.initialScale;
        item.translateX = transitem.translateX;
        item.translateY = transitem.translateY;
      } else {
        this.add(transitem);
      }
    },

    remove(transitem) {
      this.transitems.removeObject(transitem);
    },

    empty() {
      this.transitems.clear();
    }

  });

  _exports.default = _default;
});