define("ember-affine-gesture/components/affine-gesture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    component,
    set,
    get
  } = Ember; // https://books.google.com/books?id=fCZB0yBckTgC&pg=PA265&lpg=PA265&dq=webkit+js+GestureEvent+example&source=bl&ots=_8AFHeSPvv&sig=ACfU3U1hqilNpasn4wph7B1AYE7jfTIkDg&hl=en&sa=X&ved=2ahUKEwipiI6kwOLgAhWKneAKHf2AB-YQ6AEwB3oECAQQAQ#v=onepage&q=webkit%20js%20GestureEvent%20example&f=false
  // var clientX, clientY;
  // var localX, localY;

  let showEvents = false;
  const K = 180 / Math.PI;

  function copyTouch(touch) {
    return {
      identifier: touch.pointerId,
      pageX: touch.clientX,
      pageY: touch.clientY
    };
  }

  ;

  function midpoint(p1, p2) {
    return {
      x: p1.x + (p2.x - p1.x) * .5,
      y: p1.y + (p2.y - p1.y) * .5
    };
  }

  ;

  var _default = Ember.Component.extend({
    tagName: 'affine-gesture',
    attributeBindings: ['style'],
    classNames: ['affineGesture'],
    classNameBindings: ['active'],
    active: false,
    // activeTransforms: activeTransforms,
    transforms: Ember.inject.service('transforms'),
    //not lazy-loaded
    // hey: transforms(function() {  // lazy-loaded
    //   return getOwner(this).lookup('service:transforms');
    // }),
    ongoingTouches: null,
    rotation: 0,
    rotate: 0,
    initialRotation: 0,
    scale: 1,
    initialScale: 1,
    original: {
      x: 0,
      y: 0
    },
    origin: {
      x: 0,
      y: 0
    },
    origAngle: 0,
    translateX: 0,
    translateY: 0,
    mouseLastX: 0,
    mouseLastY: 0,
    midTouch: {
      x: 0,
      y: 0
    },
    location: {
      x: 0,
      y: 0
    },
    vAngle: 0,
    vDist: 0,
    showEvents: false,
    corners: {
      tl: {
        x: 0,
        y: 0,
        a: 0,
        r: 0
      },
      tr: {
        x: 0,
        y: 0,
        a: 0,
        r: 0
      },
      br: {
        x: 0,
        y: 0,
        a: 0,
        r: 0
      },
      bl: {
        x: 0,
        y: 0,
        a: 0,
        r: 0
      }
    },
    // corners.EmberObject.create( this.get('corners'));
    transcorn: {
      tl: {
        x: 0,
        y: 0
      },
      tr: {
        x: 0,
        y: 0
      },
      br: {
        x: 0,
        y: 0
      },
      bl: {
        x: 0,
        y: 0
      }
    },
    envelopes: null,
    //transform: rotate(30deg) scale(1.2) skew(10deg) translate(5px, 5px);
    style: Ember.computed('origin', 'rotate', 'scale', 'translateX', 'translateY', function () {
      // return Ember.String.htmlSafe(`transform: translate(${this.get('translateX')}px, ${this.get('translateY')}px) rotate(${this.get('rotation')}deg) scale(${this.get('scale')})`);
      const orig = this.get('origin');
      this.send('transformsToController', true);
      return Ember.String.htmlSafe(`transform-origin: ${orig.x}px ${orig.y}px; transform: translate(${this.get('translateX')}px, ${this.get('translateY')}px) rotate(${this.get('rotate')}deg) scale(${this.get('scale')});`); // return Ember.String.htmlSafe(`transform: rotate(${this.get('rotation')}deg) scale(${this.get('scale')});
      //       left: ${this.get('translateX')}px;
      //       top: ${this.get('translateY')}px;`);
    }),

    // interesting sample code...
    // var controller = App.__container__.lookup('controller:MyController');
    // var options = controller.get('options');
    // options.clear();
    // var objects = [{ /*obj1*/ }, { /*obj2*/ }, { /*obj3*/ }];
    // options.pushObjects(objects);
    didInsertElement() {
      // Initialize some state for this component
      this._super(); //set arrays on instance, otherwise it will be the same for all in class


      this.set('ongoingTouches', []); // const transforms = transforms(function() {  // lazy-loaded
      //   return getOwner(this).lookup('service:transforms');
      // });

      const self = this; // this.set(
      //   'transforms', function() {
      //     return getOwner(self).lookup('service:transforms');
      //   }
      // );
      // console.log(':::');
      // console.log(this.transforms);
      //let self = this;
      // const self = this;

      const orig = {
        x: 0,
        y: 0
      };
      const corn = {
        tl: {
          x: 0,
          y: 0,
          a: 0,
          r: 0
        },
        tr: {
          x: 0,
          y: 0,
          a: 0,
          r: 0
        },
        br: {
          x: 0,
          y: 0,
          a: 0,
          r: 0
        },
        bl: {
          x: 0,
          y: 0,
          a: 0,
          r: 0
        }
      };
      Ember.run.later(this, function () {
        corn.tl.x = corn.bl.x = 0;
        corn.tl.y = corn.tr.y = 0;
        orig.x = corn.tr.x = corn.br.x = this.element.clientWidth;
        orig.y = corn.br.y = corn.bl.y = this.element.clientHeight; //const rr = this.element.getBoundingClientRect();

        console.log("rr", orig.x, orig.y); //console.log("this.element.clientWidth", this.element.clientWidth);

        orig.x *= .5;
        orig.y *= .5;
        corn.tl.a = Math.atan2(-orig.y, -orig.x);
        corn.tr.a = Math.atan2(-orig.y, orig.x);
        corn.br.a = Math.atan2(orig.y, orig.x);
        corn.bl.a = Math.atan2(orig.y, -orig.x);
        corn.tl.r = corn.tr.r = corn.br.r = corn.bl.r = Math.sqrt(orig.x * orig.x + orig.y * orig.y); //  this.set('origin', loc );
        //  $('#events').html("x: "+orig.x.toFixed(2)+"  y: "+orig.y.toFixed(2));

        this.set('original', orig);
        this.set('origin', orig);
        this.set('corners', corn);
        this.send('transformsToController', false);
        console.log('didInsertOrigin origin:', orig.x, orig.y); // document.body.addEventListener('touchmove', function(event) {
        //   event.preventDefault();
        //   event.stopImmediatePropagation();
        // }, false);
        // this.element.addEventListener('gesturechange', function(e){self.send('gesturechanged', e);} );
        // this.element.addListener (this, 'gesturechange', this, 'gesturechanged');
      }, 1000);
    },

    // didRender() {
    //   this._super(...arguments);
    //   // const orig = this.get('original');
    //   const orig = { x:0, y:0 };
    //   const corn = {tl:{x:0, y:0, a:0, r:0}, tr:{x:0, y:0, a:0, r:0}, br:{x:0, y:0, a:0, r:0}, bl:{x:0, y:0, a:0, r:0}};
    //
    //   Ember.run.next(this, function() {
    //     // orig.x = this.element.offsetWidth*.5;
    //     // orig.y = this.element.offsetHeight*.5;
    //     corn.tl.x = corn.bl.x = 0;
    //     corn.tl.y = corn.tr.y = 0;
    //     orig.x = corn.tr.x = corn.br.x = this.element.clientWidth;
    //     orig.y = corn.br.y = corn.bl.y = this.element.clientHeight;
    //     orig.x *= .5;
    //     orig.y *= .5;
    //
    //     corn.tl.a = Math.atan2(-orig.y,-orig.x);
    //     corn.tr.a = Math.atan2(-orig.y, orig.x);
    //     corn.br.a = Math.atan2( orig.y, orig.x);
    //     corn.bl.a = Math.atan2( orig.y,-orig.x);
    //
    //     corn.tl.r = corn.tr.r = corn.br.r = corn.bl.r = Math.sqrt(orig.x*orig.x+orig.y*orig.y);
    //   //  this.set('origin', loc );
    //   // $('#events').html("x: "+orig.x.toFixed(2)+"  y: "+orig.y.toFixed(2));
    //   this.set('original', orig );
    //   this.set('origin', orig );
    //   this.set('corners', corn );
    //   console.log('didRender origin:',orig.x, orig.y);
    //
    // });
    // },
    actions: {
      transformsToController(_inmotion) {
        // console.log('+');
        const transdata = Ember.Object.create(this.get('corners'));
        transdata.reopen({
          myID: this.get('elementId'),
          inmotion: _inmotion,
          origin: this.origin,
          rotate: this.rotate,
          scale: this.initialScale,
          translateX: this.translateX,
          translateY: this.translateY
        }); // 'origin', 'rotate', 'scale', 'translateX', 'translateY'
        //  this.get('activeTransforms').

        this.transforms.update(transdata); // this.activeTransforms(transdata);
        // this.set('activeTransforms')(transdata);
        // this.send('activeTransforms', transdata);
      },

      start_1_gesture(ev) {
        this.set('active', true); // console.log('start_1_gesture');

        const orig = this.get('original'); // const rAngle = Math.atan2(ev.y, ev.x) - Math.atan2(loc.y, loc.x);

        const origX = orig.x + this.get('translateX');
        const origY = orig.y + this.get('translateY'); //determines vector angle of event location to element origin

        const vectX = ev.x - origX;
        const vectY = ev.y - origY; // $('#events').html("ev0.x: "+ev.x.toFixed(2)+" ev0.y: "+ev.y.toFixed(2));
        // $('#events').append("ev0.x: "+ev.x.toFixed(2)+" ev0.y: "+ev.y.toFixed(2));
        // $('#events').html("x: "+vectX+"  y: "+vectY);
        // $('#events').html("ev.x: "+ev.x.toFixed(2)+" ev.y: "+ev.y.toFixed(2)+"  x: "+origX.toFixed(2)+"  y: "+origY.toFixed(2));

        const rAngle = Math.atan2(vectY, vectX);
        this.set('origin', orig);
        this.set('vAngle', rAngle * (180 / Math.PI));
        this.set('vDist', Math.sqrt(vectX * vectX + vectY * vectY));
        this.set('mouseLastX', ev.x);
        this.set('mouseLastY', ev.y); // this.send('showEvent', 'a: '+angle.toFixed(2));
      },

      move_1_gesture(ev) {
        const orig = this.get('origin');
        const origX = orig.x + this.get('translateX');
        const origY = orig.y + this.get('translateY'); //determines vector angle of event location to element origin

        const vectX = ev.x - origX;
        const vectY = ev.y - origY; // $('#events').html("x: "+vectX.toFixed(2)+"  y: "+vectY.toFixed(2));
        // $('#events').html("ev.x: "+ev.x.toFixed(2)+" ev.y: "+ev.y.toFixed(2)+"  x: "+origX.toFixed(2)+"  y: "+origY.toFixed(2));

        const corn = this.get('corners');
        const rAngle = Math.atan2(vectY, vectX);
        const angle = rAngle * (180 / Math.PI); //convert radians to degrees

        const deltaA = angle - this.get('vAngle');
        const dist = Math.sqrt(vectX * vectX + vectY * vectY);
        const deltaD = dist - this.get('vDist');
        this.set('rotate', this.get('rotate') + deltaA);
        this.set('vAngle', angle); // const deltaX = ev.x - this.get('mouseLastX');
        // const deltaY = ev.y - this.get('mouseLastY');

        const deltaX = Math.cos(rAngle) * deltaD;
        const deltaY = Math.sin(rAngle) * deltaD;
        this.set('translateX', this.get('translateX') + deltaX);
        this.set('translateY', this.get('translateY') + deltaY);
        this.set('mouseLastX', ev.x);
        this.set('mouseLastY', ev.y); //    this.send('showEvent', 'a: '+angle.toFixed(2));
        // console.log('a: ',angle);
      },

      end_1_gesture(ev) {
        this.set('active', false);
      },

      start_2_gesture(ev) {
        console.log('start_2_gesture'); //  $('#events').html("ev0.x: "+ev.touches[0].clientX.toFixed(2)+" ev0.y: "+ev.touches[0].clientY.toFixed(2)+"  +  ev1.x: "+ev.touches[1].clientX.toFixed(2)+" ev1.y: "+ev.touches[1].clientY.toFixed(2));
        //  this.send('logger', 'start_2_gesture ');

        let touches = ev.changedTouches;
        let ongoingTouches = [];

        for (var i = 0; i < touches.length; i++) {
          ongoingTouches.push(copyTouch(touches[i]));
          console.log("touchstart:" + i + ".");
        }

        this.set('ongoingTouches', ongoingTouches);
        const orig = this.get('origin'); //centered transform origin of element

        const origX = orig.x + this.get('translateX'); // apparent center (actual center with translation added)

        const origY = orig.y + this.get('translateY'); //

        const midTouch = {
          x: 0,
          y: 0
        };
        midTouch.x = (ev.touches[0].clientX + ev.touches[1].clientX) / 2;
        midTouch.y = (ev.touches[0].clientY + ev.touches[1].clientY) / 2;
        const vectX = midTouch.x - origX; // distance from origin point

        const vectY = midTouch.y - origY; // to point halfway between both touch points

        const rAngle = Math.atan2(vectY, vectX);
        const vAngle = rAngle * (180 / Math.PI);
        const origAngle = vAngle + 180;
        this.set('midTouch', midTouch);
        this.set('origAngle', origAngle);
        this.set('vAngle', vAngle);
        this.set('vDist', Math.sqrt(vectX * vectX + vectY * vectY));
        this.set('mouseLastX', vectX);
        this.set('mouseLastY', vectY);
      },

      move_2_gesture(ev) {
        ev.preventDefault();
        let ongoingTouches = this.get('ongoingTouches');
        Ember.$('#events').html(`t0x: ${ev.touches[0].clientX.toFixed(2)} t0y: ${ev.touches[0].clientY.toFixed(2)}, t1x: ${ev.touches[1].clientX.toFixed(2)} t1y: ${ev.touches[1].clientY.toFixed(2)}    `); // this.send('logger', 'loc:');

        if (ev.changedTouches.length == 2) {
          const orig = this.get('origin'); //centered transform origin of element

          const origX = orig.x + this.get('translateX'); // apparent center (actual center with translation added)

          const origY = orig.y + this.get('translateY'); //

          const lastMidTouch = this.get('midTouch');
          const midTouch = {
            x: 0,
            y: 0
          };
          midTouch.x = (ev.touches[0].clientX + ev.touches[1].clientX) / 2;
          midTouch.y = (ev.touches[0].clientY + ev.touches[1].clientY) / 2;
          this.set('midTouch', midTouch);
          const midDeltaX = midTouch.x - lastMidTouch.x;
          const midDeltaY = midTouch.y - lastMidTouch.y; // this.increment('translateX', midDeltaX);
          // this.increment('translateY', midDeltaY);

          this.set('translateX', this.get('translateX') + midDeltaX);
          this.set('translateY', this.get('translateY') + midDeltaY); // let touches = ev.changedTouches;
          // for (var i = 0; i < touches.length; i++) {
          // }
          // ev.stopImmediatePropagation();
          //determines vector angle of event location to element origin

          const vectX = ev.x - origX;
          const vectY = ev.y - origY;
          const rAngle = Math.atan2(vectY, vectX);
          const angle = rAngle * (180 / Math.PI); //convert radians to degrees

          const deltaA = angle - this.get('vAngle');
          const dist = Math.sqrt(vectX * vectX + vectY * vectY);
          const deltaD = dist - this.get('vDist'); // this.set('rotation', this.get('rotation')+deltaA);
          // this.set('vAngle', angle);
          // const deltaX = ev.x - this.get('mouseLastX');
          // const deltaY = ev.y - this.get('mouseLastY');

          const deltaX = Math.cos(rAngle) * deltaD;
          const deltaY = Math.sin(rAngle) * deltaD; // this.set('translateX', this.get('translateX')+deltaX);
          // this.set('translateY', this.get('translateY')+deltaY);
          // this.set('mouseLastX', ev.x);
          // this.set('mouseLastY', ev.y);
          // this.send('showEvent', 'a: '+angle.toFixed(2));
          // console.log('a: ',angle);
        }
      },

      end_2_gesture(ev) {// ev.preventDefault();
        // ev.stopImmediatePropagation();
      },

      findPos(obj) {
        // this function will return the offset global position of element obj
        var curleft = curtop = 0; //If the browser supports offsetParent we proceed.

        if (obj.offsetParent) {
          //Every time we find a new object, we add its offsetLeft and offsetTop to curleft and curtop.
          do {
            curleft += obj.offsetLeft;
            curtop += obj.offsetTop; //The tricky bit: return value of the = operator
          } while (obj = obj.offsetParent); //No, this is not a syntax error. I don't want to use == to compare obj to obj.offsetParent (that doesn't make sense anyhow, since an element is never equal to its offsetParent).
          //Instead, I use the = assignment operator to change the value of obj to obj.offsetParent. I explain this trick in more detail in this blog post.

        }

        return [curleft, curtop];
      },

      showEvent(ev) {
        if (showEvents) Ember.$('#events').html(ev + " -+- ");
      },

      logger(logme) {
        Ember.$('#events').append(" [ " + logme + " ] ");
      },

      handle_one_touch(ev) {
        Ember.$('#events').append(ev + " 1_touch ");
        clientX = ev.touches[0].clientX;
        clientY = ev.touches[0].clientY;
      },

      handle_two_touches(ev) {
        Ember.$('#events').append(ev + " 2_touches ");
      },

      handle_three_touches(ev) {
        Ember.$('#events').append(ev + " 3_touches ");
      }

    },

    touchStart(ev) {
      ev.preventDefault(); //  this.send('logger', 'gesture.scale:'+JSON.stringify(ev));
      // console.dir(ev);

      console.log('T-start'); // this.send('showEvent', 'touchStart ');
      // this.send('showEvent', ('ev.touches.length: '+ ev.touches.length));
      // this.send('showEvent', ('ev.touches: '+ JSON.stringify(ev.touches)));

      switch (ev.touches.length) {
        case 1:
          //console.log('-+-');
          //console.log(' ::: ',ev.touches[0].clientX, " ", ev.touches[0].clientY );
          this.send('start_1_gesture', {
            x: ev.touches[0].clientX,
            y: ev.touches[0].clientY
          }); // this.send('start_1_gesture', {x: ev.clientX, y: ev.clientY});

          break;

        case 2:
          this.send('start_2_gesture', ev);
          break;

        case 3:
          this.send('3_touches', ev);
          break;

        default:
          gesture_not_supported(ev);
          break;
      }

      return true;
    },

    touchMove(ev) {
      ev.preventDefault();
      console.log('T-move'); // $('#events').html(`t0x: ${ev.touches[0].clientX.toFixed(2)} t0y: ${ev.touches[0].clientY.toFixed(2)} `);
      //  $('#events').html(`H`);
      // this.send('showEvent', 'touchMove ');
      // if (ev.targetTouches.length >= 1)
      // this.send('showEvent', ('ev.targetTouches: '+ JSON.stringify(ev.targetTouches.item(0))));
      // else
      // this.send('showEvent', ('ev.touches: '+ JSON.stringify(ev.touches.item(0).identifier )));

      switch (ev.touches.length) {
        case 1:
          this.send('move_1_gesture', {
            x: ev.touches[0].clientX,
            y: ev.touches[0].clientY
          });
          break;

        case 2:
          this.send('move_2_gesture', ev);
          break;

        case 3:
          this.send('3_touches', ev);
          break;

        default:
          gesture_not_supported(ev);
          break;
      }

      return false;
    },

    touchEnd(ev) {
      ev.preventDefault();
      this.send('showEvent', 'touchEnd ');

      switch (ev.touches.length) {
        case 1:
          this.send('end_1_gesture', {
            x: ev.touches[0].clientX,
            y: ev.touches[0].clientY
          });
          break;

        case 2:
          this.send('end_2_gesture', ev);
          break;

        case 3:
          this.send('3_touches', ev);
          break;

        default:
          gesture_not_supported(ev);
          break;
      }

      return true;
    },

    gesturestart(ev) {
      ev.preventDefault();
      const self = this; // ev.stopImmediatePropagation();

      console.log("gesture start");
      console.dir(ev);
      this.set('initialScale', this.get('scale'));
      this.set('initialRotation', this.get('rotate'));
      this.element.addEventListener('gesturechange', function (e) {
        // e.preventDefault();
        self.set('scale', self.get('initialScale') * e.scale);
        self.set('rotate', self.get('initialRotation') + e.rotation); //self.set('translateX', sel)

        console.log("gesture change");
      }); // this.send('logger', 'gesturestart '+ev.pinch);
    },

    gesturechanged(ev) {
      ev.preventDefault(); // this.set('scale', this.get('initialScale')*ev.scale );
      // this.set('rotation', this.get('initialRotation')+ev.rotation );
      //
      // console.log("gesture change");
      // console.dir(ev);
      // this.send('logger', 'gesture.scale:'+JSON.stringify(ev));
      // this.send('logger', 'gesture.scale:'+Ember.ev);
    },

    gestureend(ev) {
      ev.preventDefault();
      console.log("gesture end"); //this.send('logger', 'gestureend ');
    },

    touchCancel(ev) {
      this.send('showEvent', 'touchCancel ');
      return true;
    },

    keyDown() {
      this.send('showEvent', 'keyDown ');
      return true;
    },

    keyUp() {
      this.send('showEvent', 'keyUp ');
      return true;
    },

    keyPress() {
      showEvent('keyPress ');
      return true;
    },

    mouseEnter() {
      this.send('showEvent', 'mouseEnter ');
      return true;
    },

    mouseLeave() {
      this.send('showEvent', 'mouseLeave ');
      return true;
    },

    mouseDown(ev) {
      // console.log('ev');
      // console.dir(ev);
      this.send('showEvent', 'mouseDown '); // this.send('start_1_gesture', ev);
      // console.log('ev', ev.clientX, "  ", ev.clientY);

      this.send('start_1_gesture', {
        x: ev.clientX,
        y: ev.clientY
      });
      return true;
    },

    mouseUp(ev) {
      this.send('showEvent', 'mouseUp ');
      this.send('end_1_gesture', ev);
      return true;
    },

    mouseMove(ev) {
      //  this.send('showEvent', 'mouseMove ');
      //  this.send('move_1_gesture', ev);
      if (ev.buttons) {
        //this.send('move_1_gesture', ev);
        this.send('move_1_gesture', {
          x: ev.clientX,
          y: ev.clientY
        });
        return true;
      } else {
        return false;
      }
    },

    click() {
      // this.send('showEvent', 'click ');
      return true;
    },

    doubleClick() {
      this.send('showEvent', 'doubleClick ');
      return true;
    },

    contextMenu() {
      this.send('showEvent', 'contextMenu ');
      return true;
    },

    focusIn() {
      this.send('showEvent', 'focusIn ');
      return true;
    },

    focusOut() {
      this.send('showEvent', 'focusOut ');
      return true;
    },

    submit() {
      this.send('showEvent', 'submit ');
      return true;
    },

    change() {
      this.send('showEvent', 'change event!');
      return true;
    },

    input() {
      this.send('showEvent', 'input ');
      return true;
    },

    dragStart(ev) {
      //  this.draggable = false;
      ev.stopImmediatePropagation(); // this.send('showEvent', 'dragStart ');

      return false;
    },

    drag(ev) {
      ev.stopImmediatePropagation(); //  this.send('showEvent', 'drag ');

      this.send('move_1_gesture', {
        x: ev.clientX,
        y: ev.clientY
      });
      return true;
    },

    dragEnter() {
      this.send('showEvent', 'dragEnter ');
      return true;
    },

    dragLeave() {
      this.send('showEvent', 'dragLeave ');
      return true;
    },

    dragOver() {
      //  this.send('showEvent', 'dragOver ');
      return true;
    },

    dragEnd() {
      this.send('showEvent', 'dragEnd ');
      return true;
    },

    drop() {
      this.send('showEvent', 'drop ');
      return true;
    }

  });

  _exports.default = _default;
});